import React from "react"
import { Link } from "gatsby"
import Gallery from "../components/gallery"
import useGallery from "gatsby-theme-gallery/src/hooks/useGallery"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const images = useGallery()

  return (
    <Layout>
      <SEO title="Home" />
      <Gallery images={images} gridSize={"small"} />
    </Layout>
  )
}

export default IndexPage
